<template>
  <!-- start page-wrapper -->
  <div class="page-wrapper">
    <!-- start preloader -->
    <transition name="fade">
        <div name="fade" v-show="show" class="preloader">
            <div class="middle">
                <i class="fi flaticon-favorite-heart-button"></i>
                <i class="fi flaticon-favorite-heart-button"></i>
                <i class="fi flaticon-favorite-heart-button"></i>
                <i class="fi flaticon-favorite-heart-button"></i>
            </div>
        </div>
    </transition>
    <!-- end preloader --> 

    <!-- Start header -->
    <header id="header" class="site-header header-style-2">
        <nav class="navigation navbar navbar-default">
            <div class="container">
                <div class="navbar-header">
                    <button type="button" class="open-btn">
                        <span class="sr-only">Toggle navigation</span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                    </button>
                    <a class="navbar-brand" href="/">Nika <i class="ti ti-heart" style="font-size:20px"></i> Mitja</a>
                </div>
                <div id="navbar" class="navbar-collapse collapse navbar-right navigation-holder">
                    <button class="close-navbar"><i class="ti-close"></i></button>
                    <ul class="nav navbar-nav">
                        <li><a href="/">Domov</a></li>
                        <li><a href="#opening">Dogodek</a></li>
                        <li><a href="#story">Zgodba</a></li>
                        <li><a href="#events">Slavje</a></li>
                        <li><a href="#people">Priče</a></li>
                        <li><a href="#gifts">Darila</a></li>
                        <li><a href="#rsvp">Potrditev</a></li>
                        <!--<li class="menu-item-has-children">
                            <a href="javascript:void(0);">Blog</a>
                            <ul class="sub-menu">
                                <li><a href="blog.html">Blog</a></li>
                                <li><a href="blog-single.html">Blog Details</a></li>
                            </ul>
                        </li>-->
                    </ul>
                </div><!-- end of nav-collapse -->
            </div><!-- end of container -->
        </nav>
    </header>
    <!-- end of header -->

    <!-- start of hero -->
    <section class="hero-slider hero-style-1">
      <div class="wedding-announcement">
          <div class="couple-text">
              <h3 id="curve-text">Poročila se bova!</h3>
              <h2 class="slideInUp couple-name" data-wow-duration="1s">Nika &amp; Mitja</h2>
          </div>
      </div>

      <div class="swiper-container" v-swiper:mySwiper="swiperOption">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="slide-inner slide-bg-image" v-bind:style="{ backgroundImage: 'url(' + require('@/assets/images/slider/slide-4.jpg') + ')' }"/>
          </div>
          <div class="swiper-slide">
            <img class="slide-inner slide-bg-image" v-bind:style="{ backgroundImage: 'url(' + require('@/assets/images/slider/slide-1.jpg') + ')' }"/>
          </div>

          <div class="swiper-slide">
            <img class="slide-inner slide-bg-image" v-bind:style="{ backgroundImage: 'url(' + require('@/assets/images/slider/slide-2.jpg') + ')' }"/>
          </div>

          <div class="swiper-slide">
            <img class="slide-inner slide-bg-image" v-bind:style="{ backgroundImage: 'url(' + require('@/assets/images/slider/slide-3.jpg') + ')' }"/>
          </div>
          
          <div class="swiper-slide">
            <img class="slide-inner slide-bg-image" v-bind:style="{ backgroundImage: 'url(' + require('@/assets/images/slider/slide-5.jpg') + ')' }"/>
          </div>
        </div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>

    </section>
    <!-- end of hero slider -->

    <section class="opening-section section-padding" id="opening">
        <div class="container">
            <div class="row">
                <div class="col col-xs-12">
                    <div class="opening-form">                    
                        <p>Dragi sorodniki in prijatelji!</p>
                        <p>Najini ljubezni bova dala pridih večnosti v soboto, 24. julija 2021 ob 17:30.<br/>
                        Vabiva Vas, da delite z nama te trenutke sreče<br/>
                        in se nama pridružite po 16:30 v Villi Xaxat.
                        </p>
                        <div class="left-flower"></div>
                        <div class="right-flower"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- start wedding-date -->
    <section class="wedding-date section-padding">
        <div class="container">
            <div class="row">
                <div class="col col-xs-12">
                    <div class="section-title">
                        <h2>Shrani si datum</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12">
                    <h3>24 Julij 2021</h3>
                    <div class="clock-grids">
                        <div id="clock"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- end wedding-date -->


    <!-- start couple-section -->
    <!-- <section class="couple-section section-padding" id="couple">
        <div class="container">
            <div class="row">
                <div class="col col-xs-12">
                    <div class="couple-area clearfix">
                        <div class="text-grid bride">
                            <h3>The bride</h3>
                            <p>A bridecollection of textile samples lay spread out on the table Samsa was a travelling salesman and above it there hung a picture that he had recently cut out of an illustrated magazine and dholhos housed in a nice, gilded frame. It showed a lady fitted </p>
                            <div class="social">
                                <ul>
                                    <li><a href="tel:+38640223933"><i class="ti-mobile"></i></a></li>
                                    <li><a href="mailto:nika.genny@gmail.com?subject=Poroka"><i class="ti-email"></i></a></li>
                                    <li><a href="https://www.instagram.com/niksi14" target="_blank"><i class="ti-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="middle-couple-pic">
                            <img src="@/assets/images/couple.jpg" alt>
                        </div>
                        <div class="text-grid groom">
                            <h3>The Groom</h3>
                            <p>A bridecollection of textile samples lay spread out on the table Samsa was a travelling salesman and above it there hung a picture that he had recently cut out of an illustrated magazine and dholhos housed in a nice, gilded frame. It showed a lady fitted </p>
                            <div class="social">
                                <ul>
                                    <li><a href="tel:+38641567663"><i class="ti-mobile"></i></a></li>
                                    <li><a href="mailto:mitja.ahlin@gmail.com?subject=Poroka"><i class="ti-email"></i></a></li>
                                    <li><a href="https://www.facebook.com/mitja.ahlin" target="_blank"><i class="ti-facebook"></i></a></li>
                                    <li><a href="https://www.instagram.com/mitjaahlin" target="_blank"><i class="ti-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    <!-- end couple-section -->


    <!-- start love-sotry-section -->
    <section class="love-sotry-section section-padding" id="story">
        <div class="container">
            <div class="row">
                <div class="col col-xs-12">
                    <div class="section-title">
                        <h2>Najina zgodba</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12">
                    <div class="story-grids">
                        <div class="grid">
                            <h3>Kako sva se spoznala</h3>
                            <p>Nika in Mitja sta se spoznala na faksu. Nika je vedno sedela v prvi vrsti, Mitja pa za njo. Njeno pozornost je poskušal pritegniti tako, da je na tla metal svinčnik in jo prosil, če mu ga lahko pobere. Sprva je Nika mislila, "Joj, kakšen čuden in neroden fant". Vsakič, ko je Mitja vstopil v predavalnico , jo je prisrčno in vstrajno pozdravljal. Na začetku je Niko zelo pritegnila njegova prijaznost, nežnost in skromnost.  Prijateljstvo in naklonjenost pa sta rastla ter s časom dozorela v ljubezen.</p>
                        </div>
                        <div class="grid">
                            <h3>Prvi zmenek</h3>
                            <p>Njun prvi zmenek je bil sprehod s kužki. Mitjev izgovor je bil, da Frenky nima pasje družbe. Tako sta se Missy in Frenky pričela družiti. Nikina in Mitjeva zgodba pa se je začela plesti.</p>
                            <p>Kmalu je prišel dan, ko je Nika izrekla dve skromni besedi "LJUBIM TE". Mitja, jo je v šoku (in dežju) pustil sredi Portoroža. K sreči je prišel k pameti preden je Nika zbolela za pljučnico.</p>
                        </div>
                        <div class="grid">
                            <h3>Matej Jan</h3>
                            <p>Skrbno in nežno, kot porcelan, sta vsak dan čuvala in negovala njuno ljubezen. Prišel pa je tudi prav poseben dan, 01.03.2019, kateri jima je za vedno spremenil življenje. Rodil se je mali princ Matej Jan.</p>
                        </div>
                        <div class="grid">
                            <h3>Zaroka</h3>
                            <p>Nekega poletnega dne, je Nika sedela na kavču z bodočo taščo. Ob sproščenem klepetu sta prišli na temo poroke. Nika je rekla Sonji: "Joj, jaz bi se tako rada poročila!" in Sonja je odgovorila : "Jaz bi pa tako rada šla na kakšno poroko!"  in tako sta pričeli z načrtovanjem. Ko je bila cela družina zbrana na kosilu, je Nika vprašala Mitjo: "Mitja, bi se poročil z mano?" in on je mirno odgovoril: "Ja , z veseljem!".</p>
                        </div>
                        <img src="@/assets/images/ring.png" alt>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- end love-sotry-section -->


    <!-- start wedding-location-section -->
    <section class="wedding-location-section section-padding" id="events">
        <div class="container">
            <div class="row">
                <div class="col col-xs-12">
                    <div class="section-title">
                        <h2>Poročna lokacija</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12">
                    <div class="location-grids clearfix">
                        <!--<div class="grid">
                            <div class="img-holder">
                                <img src="@/assets/images/reception-pic.jpg" alt>
                            </div>
                            <h3>The Reception</h3>
                            <ul>
                                <li><i class="ti-location-pin"></i> Hotel, muli International Austria</li>
                                <li><i class="ti-time"></i> Feb 13, 2020 (10Am - 1AM)</li>
                            </ul>
                            <p>Recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur hat and fur boa who sat upright, raising a heavy fur muff that covered the whole of her</p>
                            <a class="see-location-btn popup-gmaps" href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3650.072217139369!2d90.40632831544468!3d23.816030892181896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c6feb6c1b7b1%3A0x76350c55ebc50c34!2sRadisson%20Blu%20Dhaka%20Water%20Garden!5e0!3m2!1sen!2sbd!4v1566623079021!5m2!1sen!2sbd">
                                See location <i class="fa fa-angle-right"></i>
                            </a>
                        </div>-->
                        <div class="grid">
                            <div class="img-holder">
                                <img src="@/assets/images/wedding-party-pic.jpg" alt>
                            </div>
                            <br/>
                            <i style='font-size:35px;color:#818492;font-weight:bold;font-family:"Great Vibes", cursive'>Villa Xaxat</i>
                            <br/>
                            <ul>
                                <li style="color:#818492"><i class="ti-location-pin"></i> Villa Xaxat, Osp</li>
                                <li style="color:#818492"><i class="ti-time"></i> Julij 24, 2021 (16:30 - ...)</li>
                            </ul>
                            <p>
                                Želiva si romantično in preprosto poroko v objemu narave.  Imela bova civilno poroko na prostem, v sanjski Villi Xaxat v prikupnem kraju Osp.
                                <br/>
                                Najina najljubša barva je modra in najljubša roža je rdeča vrtnica. Prav to bo tudi barvna shema poroke: bela, rdeča in modra. Vse bo seveda povezovalo obilo zelenja, dreves in ostalih rastlin.
                                <br/> 
                                <u> Če imate željo in možnost, se nama lahko pridružite in se tudi vi odenete v barve najine poroke!</u> 
                                <br/> 
                                <b> Na lokaciji sta bazen in otroško igrišče, zato prinesite s seboj udobnejša oblačila!</b> 
                            </p>
                            <p>
                                <b>OPOZORILO: Metanje riža na lokaciji je prepovedano!</b>
                            </p>
                            <h3 style="font-size:16px;color:#818492">Prenočitev:</h3>
                            <p>
                                Na lastno željo in stroške se lahko svatje odločite za prenočitev v vili. Villa Xaxat ponuja prenočitev z zajtrkom, ki znaša 40€ za odraslega in 20€ za otroka.
                                <br/> 
                                Če se za to odločite, nam prosim pripišite število prenočitev v obrazec za potrditev udeležbe na poroki.
                            </p>
                            <a class="see-location-btn popup-gmaps" href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1721.8210372599633!2d13.844420813371359!3d45.57582965310363!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNDXCsDM0JzMzLjEiTiAxM8KwNTAnNDAuMCJF!5e1!3m2!1sen!2sbd!4v1600074930589!5m2!1sen!2sbd">
                                Pokaži lokacijo <i class="fa fa-angle-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- end wedding-location-section -->


    <!-- start wishes-section -->
    <!-- <section class="wishes-section">
        <div class="container">
            <div class="row">
                <div class="col col-lg-8 col-lg-offset-2">
                    <div class="text">
                        <p style="font-size: 22px;">“ Zame lepšega ni,<br/>
                        kot to, da vse sem ti.<br/>
                        Zame boljšega ni,<br/>
                        kot to, da ob meni si ti. ”</p>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    <!-- end wishes-section -->


    <!-- start groomsmen-bridesmaid section -->
    <section class="groomsmen-bridesmaid section-padding  p-t-0" id="people">
        <div class="container">
            <div class="row">
                <div class="col col-xs-12">
                    <div class="section-title">
                        <h2>Priče</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12">
                    <div class="groomsmen-bridesmaid-grids clearfix">
                        <div class="grid">
                            <div class="img-holder">
                                <img src="@/assets/images/groomsmen/img-1-new.jpg" alt>
                            </div>
                            <div class="details">
                                <h4>Patricija Lovrečič</h4>
                                <p>Nevestina priča</p>
                                <ul>
                                    <li><a href="https://www.facebook.com/patricija.lovrecic" target="_blank"><i class="ti-facebook" style="font-size:25px"></i></a></li>
                                    <li><a href="tel:041-851-868" aria-label="041-851-868"><i class="ti-mobile" style="font-size:25px"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="grid">
                            <div class="img-holder">
                                <img src="@/assets/images/groomsmen/img-2-new.jpg" alt>
                            </div>
                            <div class="details">
                                <h4>Matej Kavrečič</h4>
                                <p>Ženinova priča</p>
                                <ul>
                                    <li><a href="https://www.facebook.com/profile.php?id=100014454822633" target="_blank"><i class="ti-facebook" style="font-size:25px"></i></a></li>
                                    <li><a href="tel:040-386-096" aria-label="040-386-096"><i class="ti-mobile" style="font-size:25px"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- end groomsmen-bridesmaid section -->


    <!-- start gallery-section -->
    <!-- <section class="gallery-section section-padding" id="gallery">
        <div class="container">
            <div class="row">
                <div class="col col-xs-12">
                    <div class="section-title">
                        <h2>Najina poročna galerija</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12">
                    <div class="gallery-grids">
                        <div class="grid">
                            <a href="@/assets/images/gallery/img-1.jpg" class="fancybox" data-fancybox-group="gall-1">
                                <img src="@/assets/images/gallery/img-1.jpg" alt>
                            </a>
                        </div>
                        <div class="grid">
                            <a href="@/assets/images/gallery/img-2.jpg" class="fancybox" data-fancybox-group="gall-1">
                                <img src="@/assets/images/gallery/img-2.jpg" alt>
                            </a>
                        </div>
                        <div class="grid">
                            <a href="@/assets/images/gallery/img-3.jpg" class="fancybox" data-fancybox-group="gall-1">
                                <img src="@/assets/images/gallery/img-3.jpg" alt>
                            </a>
                        </div>
                        <div class="grid">
                            <a href="@/assets/images/gallery/img-4.jpg" class="fancybox" data-fancybox-group="gall-1">
                                <img src="@/assets/images/gallery/img-4.jpg" alt>
                            </a>
                        </div>
                        <div class="grid">
                            <a href="@/assets/images/gallery/img-5.jpg" class="fancybox" data-fancybox-group="gall-1">
                                <img src="@/assets/images/gallery/img-5.jpg" alt>
                            </a>
                        </div>
                        <div class="grid">
                            <a href="@/assets/images/gallery/img-6.jpg" class="fancybox" data-fancybox-group="gall-1">
                                <img src="@/assets/images/gallery/img-6.jpg" alt>
                            </a>
                        </div>
                        <div class="grid">
                            <a href="@/assets/images/gallery/img-7.jpg" class="fancybox" data-fancybox-group="gall-1">
                                <img src="@/assets/images/gallery/img-7.jpg" alt>
                            </a>
                        </div>
                        <div class="grid">
                            <a href="https://www.youtube.com/embed/7GWSl1GQSSc" data-type="iframe" class="video-play-btn">
                                <img src="@/assets/images/gallery/img-8.jpg" alt class="img img-responsive">
                                <i class="ti ti-control-play"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    <!-- end gallery-section -->

 <!-- start gift-registry-section -->
    <section class="gift-registry-section section-padding p-t-0" id="gifts">
        <div class="container">
            <div class="row">
                <div class="col col-lg-8 col-lg-offset-2">
                    <div class="section-title-s2">
                        <h2>Poročni seznam</h2>
                        <p>Ker imamo stanovanje večinoma opremljeno, je bila priprava spodnjega seznama zelo premišljena. Na njem so tako manjša darila, kot tudi večja/skupinska darila. Če ne najdete nič po vašem okusu ali zmožnostih, bova vesela vsakega darila ali prispevka.</p>
                        <br/>
                        <p>
                            Poročni seznam sva sestavila samo zato, da bo vam v pomoč pri izbiri darila.
                            <br/>
                            DARILO NIKAKOR NI POGOJ ZA UDELEŽBO NA NAJINI POROKI!
                            <br/>
                            Iskreno si želimo in težko pričakujemo druženja z vami!
                        </p>
                        <br/>
                        <p>Kliknite ikono za ogled seznama:</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-4 col-xs-offset-4">
                    <div class="partner-grids clearfix">
                        <div>
                            <a v-bind:href="checklist.url" target="_blank">
                                <img v-bind:src="checklist.img" style="max-width:50%">
                            </a>
                            <!-- <a href="/"><i  style="font-size:50px"></i></a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- end gift-registry-section -->

    <!-- start rsvp-section -->
    <section class="rsvp-section section-padding" id="rsvp">
        <div class="container">
            <div class="row">
                <div class="col col-xs-12">
                    <div class="rspv-form">
                        <h3>Ali pridete?</h3>
                        <!-- <p>Prijazno vas prosimo da se prijavite pred 12.6.2021</p> -->
                        <p class="custom-text-nika" style="font-size: 16px;color:#818492">Vesela in počaščena bova,</p>
                        <p class="custom-text-nika" style="font-size: 16px;color:#818492">če nama boste s svojo prisotnostjo polepšali ta dan!</p>
                        <br/>
                        <p>Prosiva, da svojo udeležbo sporočite najkasneje do <i style="font-weight:bold;color:#818492">31.05.2021.</i></p>
                        <div class="left-flower"></div>
                        <div class="right-flower"></div>
                        <form id="rsvp-form" class="clearfix form validate-rsvp-form row" method="post">
                            <div>
                                <input type="text" name="name" class="form-control" v-model="form.name" readonly="true" style="background:transparent">
                            </div>
                            <div>
                                <input type="email" name="email" class="form-control" v-model="form.email" readonly="true" style="background:transparent">
                            </div>
                            <div>
                                <select class="form-control" name="guest" v-model="form.guests" >
                                    <option disabled selected>Število svatov*</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                </select>
                            </div>
                            <div>
                                <!-- <textarea class="form-control" name="notes" placeholder="Vaše sporočilo*"></textarea> -->
                                <textarea class="form-control" name="notes" v-model="form.additional" style="border:1px solid #e9ebf2"
                                    placeholder="Vaše sporočilo (imena svatov, razne alergije ali posebnosti, prenočišče,...)*"></textarea>
                            </div>
                            <div class="submit-btn">
                                <button type="submit" class="theme-btn">POŠLJI</button>
                                <div id="loader">
                                    <i class="ti-reload"></i>
                                </div>
                            </div>
                            <div class="success-error-message">
                                <div id="success">Hvala za sporočilo</div>
                                <div id="error"> Težava pri pošiljanju sporočila. Prosimo javite nam po telefonu. </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- end rsvp-section -->

    <!-- start footer-section -->
    <footer class="footer-section">
        <div class="container">
            <div class="row">
                <div class="col col-xs-12">
                    <div class="footer-area">
                        <div class="couple-name">
                            <h2>Nika <br>&amp;<br>Mitja</h2>
                        </div>
                        <h3>Forever our love, Thank you</h3>
                        <p>Copyright 2021, Made with love by <b>Nika &amp; Mitja</b></p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <!-- end footer-section -->
  </div>
</template>

<script>
import {WOW} from 'wowjs'
import { getInstance } from "../auth/authWrapper";
import axios from 'axios';

export default {
    name: 'Wedding',
    components: {

    },
    data() {
        return {
            show: true,
            form: {
                name: '',
                email: '',
                guests: '',
                additional: ''
            },
            swiperOption: {
                loop: true,
                speed: 1000,
                parallax: true,
                autoplay: {
                    delay: 6500,
                    disableOnInteraction: false,
                },
                watchSlidesProgress: true,

                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },

                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },

                on: {
                    progress: function() {
                        var swiper = this;
                        for (var i = 0; i < swiper.slides.length; i++) {
                            var slideProgress = swiper.slides[i].progress;
                            var innerOffset = swiper.width * 0.5;
                            var innerTranslate = slideProgress * innerOffset;
                            swiper.slides[i].querySelector(".slide-inner").style.transform =
                            "translate3d(" + innerTranslate + "px, 0, 0)";
                        }      
                    },

                    touchStart: function() {
                    var swiper = this;
                    for (var i = 0; i < swiper.slides.length; i++) {
                        swiper.slides[i].style.transition = "";
                    }
                    },

                    setTransition: function(speed) {
                        var swiper = this;
                        for (var i = 0; i < swiper.slides.length; i++) {
                            swiper.slides[i].style.transition = speed + "ms";
                            swiper.slides[i].querySelector(".slide-inner").style.transition =
                            speed + "ms";
                        }
                    }
                }
            },
            checklist: {
                img: require('@/assets/images/checklist.png'),
                caption: 'Poročni seznam',
                title: 'Naš poročni seznam',
                url: "https://docs.google.com/spreadsheets/d/1sU3lcbZDY7hPwjhzQdCg8yVbTNcXgEVBlO4j3NQ4-HM/edit?usp=sharing"
            }
        }
    },
    methods: {
        // enter: function() {
        //     var that = this;
        //     setTimeout(function() {
        //         that.show = false;
        //     }, 3000); // hide the message after 3 seconds
        // }
    },
    mounted() {
        setTimeout(function() {
            this.show = false;
        }.bind(this), 2000); // hide the message after 3 seconds
            
        global.jQuery = require('jquery');
        var $ = global.jQuery;
        
        console.log('Current Swiper instance object', this.mySwiper)
        this.mySwiper.slideTo(1, 1000, false)

        const authService = getInstance();
        this.form.email = authService.user.email;
        this.form.name = authService.user.name;

        // COUNTDOWN CLOCK
        if ($("#clock").length) {
            $('#clock').countdown('2021/7/24 16:30:00', function(event) {
                $(this).html(event.strftime(''
                + '<div class="box"><div><div class="time">%D</div> <span>Dni</span> </div></div>'
                + '<div class="box"><div><div class="time">%H</div> <span>Ur</span> </div></div>'
                + '<div class="box"><div><div class="time">%M</div> <span>Minut</span> </div></div>'
                + '<div class="box"><div><div class="time">%S</div> <span>Sekund</span> </div></div>'));
            });
        }

        // HERO CURVE TEXT
        if($("#curve-text").length) {
            var $curveText   = $('#curve-text').hide();
            $curveText.show().arctext({radius: 150, dir:1, animation: '300ms'});
        }

        // POPUP YOUTUBE, VIMEO, GMAPS
        $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: false,
            fixedContentPos: false
        });

        // ACTIVE GALLERY POPUP IMAGE
        if ($(".popup-gallery").length) {
            $('.popup-gallery').magnificPopup({
                delegate: 'a',
                type: 'image',

                gallery: {
                enabled: true
                },

                zoom: {
                    enabled: true,

                    duration: 300,
                    easing: 'ease-in-out',
                    opener: function(openerElement) {
                        return openerElement.is('img') ? openerElement : openerElement.find('img');
                    }
                }
            });
        }

        // ACTIVE POPUP IMAGE
        if ($(".fancybox").length) {
            $(".fancybox").fancybox({
                openEffect  : "elastic",
                closeEffect : "elastic",
                wrapCSS     : "project-fancybox-title-style"
            });
        }

        // POPUP VIDEO
        if ($(".video-play-btn").length) {
            $(".video-play-btn").on("click", function(){
                $.fancybox({
                    href: this.href,
                    type: $(this).data("type"),
                    'title'         : this.title,
                    helpers     : {
                        title : { type : 'inside' },
                        media : {}
                    },

                    beforeShow : function(){
                        $(".fancybox-wrap").addClass("gallery-fancybox");
                    }
                });
                return false
            });
        }

        // Toggle mobile navigation
        function toggleMobileNavigation() {
            var navbar = $(".navigation-holder");
            var openBtn = $(".navbar-header .open-btn");
            var closeBtn = $(".navigation-holder .close-navbar");
            var body = $(".page-wrapper");
            var navLinks = $("#navbar > ul > li > a[href^='#']");

            openBtn.on("click", function() {
                if (!navbar.hasClass("slideInn")) {
                    navbar.addClass("slideInn");
                    body.addClass("body-overlay");
                }
                return false;
            })

            closeBtn.on("click", function() {
                if (navbar.hasClass("slideInn")) {
                    navbar.removeClass("slideInn");
                }
                body.removeClass("body-overlay");
                return false;
            })

            navLinks.on("click", function() {
                if (navbar.hasClass("slideInn")) {
                    navbar.removeClass("slideInn");
                }
                body.removeClass("body-overlay");
                return false;
            })
        }

        toggleMobileNavigation();


        // Function for toggle class for small menu
        function toggleClassForSmallNav() {
            var windowWidth = window.innerWidth;
            var mainNav = $("#navbar > ul");

            if (windowWidth <= 991) {
                mainNav.addClass("small-nav");
            } else {
                mainNav.removeClass("small-nav");
            }
        }

        toggleClassForSmallNav();


        // Function for small menu
        function smallNavFunctionality() {
            var windowWidth = window.innerWidth;
            var mainNav = $(".navigation-holder");
            var smallNav = $(".navigation-holder > .small-nav");
            var subMenu = smallNav.find(".sub-menu");
            var megamenu = smallNav.find(".mega-menu");
            var menuItemWidthSubMenu = smallNav.find(".menu-item-has-children > a");

            if (windowWidth <= 991) {
                subMenu.hide();
                megamenu.hide();
                menuItemWidthSubMenu.on("click", function(e) {
                    var $this = $(this);
                    $this.siblings().slideToggle();
                    e.preventDefault();
                    e.stopImmediatePropagation();
                })
            } else if (windowWidth > 991) {
                mainNav.find(".sub-menu").show();
                mainNav.find(".mega-menu").show();
            }
        }

        smallNavFunctionality();


        // function for active menuitem
        function activeMenuItem($links) {
            var top = $(window).scrollTop(),
                // windowHeight = $(window).height(),
                // documentHeight = $(document).height(),
                cur_pos = top + 2,
                sections = $("section"),
                nav = $links,
                nav_height = nav.outerHeight();


            sections.each(function() {
                var top = $(this).offset().top - nav_height,
                    bottom = top + $(this).outerHeight();

                if (cur_pos >= top && cur_pos <= bottom) {
                    nav.find("> ul > li > a").parent().removeClass("current-menu-item");
                    nav.find("a[href='#" + $(this).attr('id') + "']").parent().addClass("current-menu-item");
                } else if (cur_pos === 2) {
                    nav.find("> ul > li > a").parent().removeClass("current-menu-item");
                }

            });
        }


        // smooth-scrolling
        function smoothScrolling($scrollLinks, $topOffset) {
            var links = $scrollLinks;
            var topGap = $topOffset;

            links.on("click", function() {
                if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
                    var target = $(this.hash);
                    target = target.length ? target : $("[name=" + this.hash.slice(1) +"]");
                    if (target.length) {
                        $("html, body").animate({
                        scrollTop: target.offset().top - topGap
                    }, 1000, "easeInOutExpo");
                        return false;
                    }
                }
                return false;
            });
        }


        // Parallax background
        // function bgParallax() {
        //     if ($(".parallax").length) {
        //         $(".parallax").each(function() {
        //             var height = $(this).position().top;
        //             var resize     = height - $(window).scrollTop();
        //             var doParallax = -(resize/5);
        //             var positionValue   = doParallax + "px";
        //             var img = $(this).data("bg-image");

        //             $(this).css({
        //                 backgroundImage: "url(" + img + ")",
        //                 backgroundPosition: "50%" + positionValue,
        //                 backgroundSize: "cover"
        //             });
        //         });
        //     }
        // }

        // DATA BACKGROUND IMAGE
        var sliderBgSetting = $(".slide-bg-image");
        sliderBgSetting.each(function(){
            if ($(this).attr("data-background")){
                $(this).css("background-image", "url(" + $(this).data("background") + ")");
            }
        });

        // // HIDE PRELOADER
        function preloader() {
            if($('.preloader').length) {
                $('.preloader').delay(100).fadeOut(500, function() {
                    //active wow
                    wow.init();
                });
            }
        }

        // WOW ANIMATION SETTING
        var wow = new WOW({
            boxClass:     'wow',      // default
            animateClass: 'animated', // default
            offset:       0,          // default
            mobile:       true,       // default
            live:         true        // default
        });

        // FUNCTION FORM SORTING GALLERY
        function sortingGallery() {
            if ($(".sortable-gallery .gallery-filters").length) {
                var $container = $('.gallery-container');
                $container.isotope({
                    filter:'*',
                    animationOptions: {
                        duration: 750,
                        easing: 'linear',
                        queue: false,
                    }
                });

                $(".gallery-filters li a").on("click", function() {
                    $('.gallery-filters li .current').removeClass('current');
                    $(this).addClass('current');
                    var selector = $(this).attr('data-filter');
                    $container.isotope({
                        filter:selector,
                        animationOptions: {
                            duration: 750,
                            easing: 'linear',
                            queue: false,
                        }
                    });
                    return false;
                });
            }
        }

        sortingGallery();

        // MASONRY GALLERY SETTING
        // function masonryGridSetting() {
        //     if ($('.masonry-gallery').length) {
        //         var $grid =  $('.masonry-gallery').masonry({
        //             itemSelector: '.grid-item',
        //             columnWidth: '.grid-item',
        //             percentPosition: true
        //         });

        //         $grid.imagesLoaded().progress( function() {
        //             $grid.masonry('layout');
        //         });
        //     }
        // }

        // masonryGridSetting();


        // STICKY HEADER

        // Function for clone an element for sticky menu
        function cloneNavForSticyMenu($ele, $newElmClass) {
            $ele.addClass('original').clone().insertAfter($ele).addClass($newElmClass).removeClass('original');
        }

        // clone home style 1 navigation for sticky menu
        if ($('.site-header .navigation').length) {
            cloneNavForSticyMenu($('.site-header .navigation'), "sticky");
        }

        //clone home style 1 navigation for sticky menu
        if ($('.header-style-2 .navigation').length) {
            cloneNavForSticyMenu($('.header-style-2 .navigation'), "sticky-2");
        }

        // Function for sticky menu
        function stickIt($stickyClass, $toggleClass, $topOffset) {
            if ($(window).scrollTop() >= $topOffset) {
                var orgElement = $(".original");
                var widthOrgElement = orgElement.css("width");

                $stickyClass.addClass($toggleClass);

                $stickyClass.css({
                    "width": widthOrgElement
                }).show();

                $(".original").css({
                    "visibility": "hidden"
                });

            } else {

                $(".original").css({
                    "visibility": "visible"
                });

                $stickyClass.removeClass($toggleClass);
            }
        }

        // POST SLIDER
        if($(".post-slider".length)) {
            $(".post-slider").owlCarousel({
                mouseDrag: false,
                smartSpeed: 500,
                margin: 30,
                loop:true,
                nav: true,
                navText: ['<i class="fi flaticon-back"></i>','<i class="fi flaticon-next"></i>'],
                dots: false,
                items: 1
            });
        }  


        // VIDEO BACKGROUND
        // if ($("#video-background").length) {
        //     $('#video-background').YTPlayer({
        //         showControls: false,
        //         playerVars: {
        //             modestbranding: 0,
        //             autoplay: 1,
        //             controls: 1,
        //             showinfo: 0,
        //             wmode: 'transparent',
        //             branding: 0,
        //             rel: 0,
        //             autohide: 0,
        //             origin: window.location.origin
        //         }
        //     });
        // }


        // WATER RIPPLE
        if ($(".ripple").length) {
            $('.ripple').ripples({
                resolution: 512,
                dropRadius: 20, //px
                perturbance: 0.04,
            });

            // Automatic drops
            setInterval(function() {
                var $el = $('.ripple');
                var x = Math.random() * $el.outerWidth();
                var y = Math.random() * $el.outerHeight();
                var dropRadius = 20;
                var strength = 0.04 + Math.random() * 0.04;

                $el.ripples('drop', x, y, dropRadius, strength);
            }, 400);
        }

        // GOOGLE MAP
        // function map() {

        //     var locations = [
        //         ['Hotel royal international khulna ', 22.8103888, 89.5619609,1],
        //         ['City inn khulna', 22.820884, 89.551216,2],
        //     ];

        //     var map = new google.maps.Map(document.getElementById('map'), {
        //         center: new google.maps.LatLng( 22.8103888, 89.5619609),
        //         zoom: 12,
        //         scrollwheel: false,
        //         mapTypeId: google.maps.MapTypeId.ROADMAP

        //     });

        //     var infowindow = new google.maps.InfoWindow();

        //     var marker, i;

        //     for (i = 0; i < locations.length; i++) {
        //             marker = new google.maps.Marker({
        //             position: new google.maps.LatLng(locations[i][1], locations[i][2]),
        //             map: map,
        //             icon:'images/map-marker.png'
        //         });

        //         google.maps.event.addListener(marker, 'click', (function(marker, i) {
        //             return function() {
        //                 infowindow.setContent(locations[i][0]);
        //                 infowindow.open(map, marker);
        //             }
        //         })(marker, i));
        //     }
        // };

        // RSVP FORM SUBMISSION
        if ($("#rsvp-form").length) {
            $("#rsvp-form").validate({
                rules: {
                    name: {
                        required: true,
                        minlength: 2
                    },
                    email: "required",
                    guest: {
                        required: true
                    }
                },

                messages: {
                    // name: "Prosimo vpišite vaše ime",
                    // email: "Prosimo vpišite vaš email",
                    guest: "Prosimo izberite število svatov"
                },

                submitHandler: function () {
                    const params = new URLSearchParams()
                    params.append('name', this.form.name)
                    params.append('email', this.form.email)
                    params.append('guest', this.form.guests)
                    params.append('notes', this.form.additional)

                    console.log(this.form)

                    const config = {
                        headers: {
                            //'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }

                    axios.post("https://wedding.ahlin.si/mail.php", params, config)
                    .then(response => {
                        console.log(response)
                        $( "#loader").hide();
                        $( "#success").slideDown( "slow" );
                        setTimeout(function() {
                            $( "#success").slideUp( "slow" );
                        }.bind(this), 3000);
                        //form.reset();
                    }).catch(error => {
                        console.log(error)
                        $( "#loader").hide();
                        $( "#error").slideDown( "slow" );
                        setTimeout(function() {
                            $( "#error").slideUp( "slow" );
                        }.bind(this), 3000);
                    })

                    return false; // required to block normal submit since you used ajax
                }.bind(this)

            });
        }

        // WHEN DOCUMENT LOADING
        $(window).on('load', function() {
            preloader();
            toggleMobileNavigation();
            smallNavFunctionality();
            smoothScrolling($("#navbar > ul > li > a[href^='#']"), $(".site-header .navigation").innerHeight());
        });

        // WHEN WINDOW SCROLL
        $(window).on("scroll", function() {
            if ($(".header-style-1").length) {
                stickIt($(".sticky"), "sticky-on", $(".header-style-1 .navigation").offset().top);
            }
            if ($(".header-style-2").length) {
                stickIt($(".sticky-2"), "sticky-on", 500);
            }
            activeMenuItem($(".navigation-holder"));
        });

        // WHEN WINDOW RESIZE
        $(window).on("resize", function() {
            toggleClassForSmallNav();
            clearTimeout($.data(this, 'resizeTimer'));
            $.data(this, 'resizeTimer', setTimeout(function() {
                smallNavFunctionality();
            }, 200));
        });
    }
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s
}

.fade-enter,
.fade-leave-to
/* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}

.custom-text-nika {
    color: black;
}
</style>
