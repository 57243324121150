import Vue from 'vue'
import VueRouter from 'vue-router'
import Wedding from '../views/Wedding.vue'
import { authGuard } from "../auth";

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Wedding', component: Wedding, beforeEnter: authGuard },
]

const router = new VueRouter({
  //mode: 'history',
  routes
})

export default router
