<template>
  <div id="app">
    <!-- <div id="nav">
      <div>
        <button @click="login">Log in</button>
        <button @click="logout">Log out</button>
      </div>
      <router-link to="/">Wedding</router-link>
    </div> -->
    <router-view/>
  </div>
</template>

<script>
import '@/assets/css/themify-icons.css'
import '@/assets/css/flaticon.css'
import '@/assets/css/bootstrap.min.css'
import '@/assets/css/animate.css'
import '@/assets/css/owl.carousel.css'
import '@/assets/css/owl.theme.css'
import '@/assets/css/slick.css'
import '@/assets/css/slick-theme.css'
import '@/assets/css/swiper.min.css'
import '@/assets/css/owl.transitions.css'
import '@/assets/css/magnific-popup.css'
import '@/assets/css/style.css'

// global.jQuery = require('jquery');
// var $ = global.jQuery;
// window.$ = $;
// window.jQuery = $;
window.$ = window.jQuery = require('jquery');
require('bootstrap')

export default {
  name: "app",
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    }
  },
  mounted () {
    this.$loadScript('/js/wow.min.js');
    this.$loadScript('/js/jquery-plugin-collection.js');
    this.$loadScript('/js/jquery.arctext.js');
    // this.$loadScript('/js/jquery.mb.YTPlayer.src.js');
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
